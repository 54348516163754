window.addEventListener("load", function() {
  var lang = 'de';
  if (document.getElementsByTagName('html')[0].getAttribute('lang')) {
    lang = document.getElementsByTagName('html')[0].getAttribute('lang');
  }
  if (document.cookie.split(';').filter(function(item) {
      return item.indexOf('cookiebanner=allow') >= 0
  }).length) {
    ItsOk(lang);
  } else {
    var options = {
      'text': {
        'de': 'Diese Webseite verwendet Cookies. Cookies werden zur Benutzerführung verwendet und helfen dabei, diese Webseite zu verbessern. Durch die weitere Nutzung dieser Webseite erklären Sie sich mit unserer Cookie-Police einverstanden.',
        'en': 'This website uses cookies. Cookies are used for user guidance and help to improve this website. By continuing to use this website, you agree to our cookie policy.',
      },
      'more': {
        'de': 'Mehr erfahren',
        'en': 'Learn more',
      },
      'more_link': {
        'de': '/datenschutz',
        'en': '/en/privacy',
      },
      'btn_ok': {
        'de': 'OK',
        'en': 'OK',
      },
      'btn_abort': {
        'de': 'Ablehnen',
        'en': 'Reject',
      }
    };
    var cookiebanner = document.createElement('div');
    cookiebanner.setAttribute('id', 'cookiebanner');
    cookiebanner.style.position = 'fixed';
    cookiebanner.style.bottom = 0;
    cookiebanner.style.left = 0;
    cookiebanner.style.right = 0;
    cookiebanner.style.zIndex = 10000;
    cookiebanner.style.backgroundColor = '#ad1a24';
    cookiebanner.style.padding = '7px 10px';
    cookiebanner.style.borderTop = '1px solid #CCC';
    cookiebanner.style.color = '#FFF';

    var text = document.createElement('div');
    text.textContent = options.text[options.text[lang] ? lang : 'de'];
    text.style.float = 'left';
    text.style.width = '70%';

    var more = document.createElement('a');
    more.setAttribute('href', options.more_link[options.more_link[lang] ? lang : 'de']);
    more.setAttribute('target', '_blank');
    more.textContent = ' ' + options.more[options.more[lang] ? lang : 'de'];
    text.appendChild(more);
    cookiebanner.appendChild(text);

    var buttons = document.createElement('div');
    buttons.setAttribute('id', 'cookiebanner_buttons');
    buttons.style.float = 'right';
    buttons.style.width = '30%';
    buttons.style.textAlign = 'right';

    var ok = document.createElement('a');
    ok.setAttribute('href', '#');
    ok.classList.add('btn');
    ok.classList.add('btn-primary');
    ok.classList.add('px-4');
    ok.onclick = function(e) {
      e.preventDefault();
      var CookieDate = new Date;
      CookieDate.setFullYear(CookieDate.getFullYear() +10);
      document.cookie = 'cookiebanner=allow; expires=' + CookieDate.toUTCString() + '; path=/';
      console.log(document.cookie)
      document.getElementById('cookiebanner').remove();
      document.getElementById('cookiebanner_padding').remove();
      ItsOk(lang);
    };
    ok.textContent = options.btn_ok[options.btn_ok[lang] ? lang : 'de'];
    ok.style.padding = '5px';
    buttons.appendChild(ok);

    var abort = document.createElement('a');
    abort.setAttribute('href', '#');
    ok.classList.add('btn');
    ok.classList.add('px-4');
    abort.onclick = function(e) {
      e.preventDefault();
      document.getElementById('cookiebanner').remove();
      document.getElementById('cookiebanner_padding').remove();
    };
    abort.textContent = options.btn_abort[options.btn_abort[lang] ? lang : 'de'];
    abort.style.padding = '5px';
    buttons.appendChild(abort);

    cookiebanner.appendChild(buttons);

    document.body.appendChild(cookiebanner);

    var cookiebanner_padding = document.createElement('div');
    cookiebanner_padding.setAttribute('id', 'cookiebanner_padding');
    cookiebanner_padding.style.height = document.getElementById('cookiebanner').offsetHeight + 'px';
    document.body.appendChild(cookiebanner_padding);
  }

  $(window).resize(function() {
    aspectRatio();
  });

  document.getElementById('cookiebanner-reset').onclick = function(e) {
    e.preventDefault();
    document.cookie = 'cookiebanner=; path=/';
    window.location.reload();
  }
});
